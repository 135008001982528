import { i18n } from "@/main";
import { EmbedData } from "@/models/EmbedData";
import { SelectOption } from "@/models/SelectOption";
import { ApiService } from "./ApiService";
import { BusyService } from "./BusyService";
class ReportsServiceClass{

  reportData: EmbedData | null = null;
  currentReportId = '';
  fullScreen = false;

  setCurrentReportId(reportId: string){
    this.currentReportId = reportId;
  }

  get selectOptions(): SelectOption[]{
    return this.availableReports
      ?.filter(r => !r.reportName.includes('Earnings Dashboard'))
      .map(r => ({
        label: r.reportName,
        value: r.reportId
      })) ?? [];
  }

  get availableReports(){
    return this.reportData?.embedReport ?? null;
  }

  get earningsReportId(){
    return this.availableReports?.find(r => r.reportName.includes('Earnings Dashboard'))?.reportId ?? '';
  }

  get metaInfo() {
    let title = i18n.t('R.LIT_Reports').toString();

    if (this.availableReports?.length){
      const report = this.availableReports.find(r => r.reportId === this.currentReportId)
      if (report){
        title += ': ' + report.reportName;
      }
    }
    return {
      title: title
    }
  }

  get embedReport(){
    return this.availableReports?.find(r => r.reportId === this.currentReportId) || null;
  }

  get expired(){
    if (!this.reportData){
      return;
    }
    return new Date(this.reportData.embedToken.expiration) <= new Date();
  }

  async getEmbedInfo(){
    if (!this.reportData || this.expired ){
      this.reportData = await ApiService.get('/api/GetEmbedInfo') as EmbedData;
    }
  }

  get isFullScreen(){
    return this.fullScreen;
  }
  
  resetFullScreen(){
    this.fullScreen = false;
  }

  toggleFullScreen() {
    BusyService.showBusy();
    const dashboardContainer = document.getElementById('report-container') as HTMLElement;

    if (!document.fullscreenElement) {
      this.fullScreen = true;
      if (dashboardContainer.requestFullscreen) {
        dashboardContainer.requestFullscreen();
      } else if ((dashboardContainer as any).mozRequestFullScreen) { /* Firefox */
        (dashboardContainer as any).mozRequestFullScreen();
      } else if ((dashboardContainer as any).webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        (dashboardContainer as any).webkitRequestFullscreen();
      } else if ((dashboardContainer as any).msRequestFullscreen) { /* IE/Edge */
        (dashboardContainer as any).msRequestFullscreen();
      }

    } else {
      if (document.exitFullscreen) {
        this.fullScreen = false;
        document.exitFullscreen();
      } else if ((document as any).mozCancelFullScreen) { /* Firefox */
        (document as any).mozCancelFullScreen();
      } else if ((document as any).webkitExitFullscreen) { /* Chrome, Safari and Opera */
        (document as any).webkitExitFullscreen();
      } else if ((document as any).msExitFullscreen) { /* IE/Edge */
        (document as any).msExitFullscreen();
      }
    }
    BusyService.hideBusy();
  }

}
export const ReportsService = new ReportsServiceClass();
